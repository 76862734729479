<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="productAttr" :rules="rules" ref="productAttrFrom" label-width="150px">
      <el-form-item label="属性名称：" prop="name">
        <el-input v-model="productAttr.name"></el-input>
      </el-form-item>

      <el-form-item label="属性值可选值列表:">
        <el-input :autosize="true" type="textarea" v-model="inputListFormat"></el-input>
      </el-form-item>
      <el-form-item label="Localization" prop="localization">
        <el-select v-model="productAttr.localization">
          <el-option v-for="item in ['CHINESE_SIMPLIFIED', 'ENGLISH']" :key="item" :value="item" :label="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('productAttrFrom')">提交</el-button>
        <el-button v-if="!isEdit" @click="resetForm('productAttrFrom')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { fetchList } from '@/api/productAttrCate';
import {
  createProductAttr,
  getProductAttr,
  updateProductAttr,
} from '@/api/productAttr';

const defaultProductAttr = {
  inputList: [''],
  sortOrder: 0,
  name: '',
  localization: 'CHINESE_SIMPLIFIED',
  attributeCategoryID: 0,
};
export default {
  name: 'ProductAttrDetail',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productAttr: { ...defaultProductAttr },
      rules: {
        name: [
          { required: true, message: '请输入属性名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur',
          },
        ],
      },
      inputListFormat: null,
    };
  },
  created() {
    this.productAttr.attributeCategoryID = this.$route.query.cid;
    if (this.isEdit) {
      getProductAttr(this.$route.query.id).then((response) => {
        this.productAttr = response.data;
        this.inputListFormat = this.productAttr.attributeValues.join();
      });
    } else {
      this.resetProductAttr();
    }
    this.getCateList();
  },
  watch: {
    inputListFormat(newValue, oldValue) {
      const input = newValue.replace(/\n/g, ',');
      this.productAttr.inputList = input.split(',');
    },
  },
  methods: {
    getCateList() {
      const listQuery = { pageNum: 1, pageSize: 100 };
      fetchList(listQuery).then((response) => {
        this.productAttrCateList = response.data.data;
      });
    },
    resetProductAttr() {
      this.productAttr = { ...defaultProductAttr };
      this.productAttr.attributeCategoryID = Number(this.$route.query.cid);
    },
    onSubmit(formName) {
      this.productAttr.attributeCategoryID = this.$route.query.cid;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            if (this.isEdit) {
              updateProductAttr(this.$route.query.id, this.productAttr).then(
                (response) => {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.$router.back();
                },
              );
            } else {
              createProductAttr(this.productAttr).then((response) => {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000,
                });
                this.resetForm('productAttrFrom');
              });
            }
          });
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.resetProductAttr();
    },
  },
};
</script>

<style scoped>
</style>
